import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './guards/authorize.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'acesso/autenticar',
    //redirectTo: 'grafico',
    pathMatch: 'full'
  },
  {
    path: 'linha-listar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/linha-listar/linha-listar.module').then(m => m.LinhaListarPageModule)
  },
  {
    path: 'linha-adicionar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/linha-adicionar/linha-adicionar.module').then(m => m.LinhaAdicionarPageModule)
  },
  {
    path: 'acesso/autenticar',
    loadChildren: () => import('./pages/acesso/autenticar/autenticar.module').then(m => m.AutenticarPageModule)
  },
  {
    path: 'linha-alterar/:id',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/linha-alterar/linha-alterar.module').then(m => m.LinhaAlterarPageModule)
  },
  {
    path: 'importacao',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/importacao/importacao.module').then(m => m.ImportacaoPageModule)
  },
  {
    path: 'importacao-arquivo-bilhetagem',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/importacao-arquivo-bilhetagem/importacao-arquivo-bilhetagem.module').then(m => m.ImportacaoArquivoBilhetagemPageModule)
  },
  {
    path: 'bilhetagem-listar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/bilhetagem-listar/bilhetagem-listar.module').then(m => m.BilhetagemListarPageModule)
  },
  {
    path: 'terminal-listar/:idLinha',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/terminal-listar/terminal-listar.module').then(m => m.TerminalListarPageModule)
  },
  {
    path: 'terminal-adicionar/:idLinha',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/terminal-adicionar/terminal-adicionar.module').then(m => m.TerminalAdicionarPageModule)
  },
  {
    path: 'terminal-alterar/:idLinha/:id',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/terminal-alterar/terminal-alterar.module').then(m => m.TerminalAlterarPageModule)
  },

  {
    path: 'tipo-veiculo-listar/:idLinha',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/tipo-veiculo-listar/tipo-veiculo-listar.module').then(m => m.TipoVeiculoListarPageModule)
  },
  {
    path: 'tipo-veiculo-adicionar/:idLinha',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/tipo-veiculo-adicionar/tipo-veiculo-adicionar.module').then(m => m.TipoVeiculoAdicionarPageModule)
  },
  {
    path: 'tipo-veiculo-alterar/:idLinha/:id',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/tipo-veiculo-alterar/tipo-veiculo-alterar.module').then(m => m.TipoVeiculoAlterarPageModule)
  },
  {
    path: 'demanda-passageiro/:idEstrategia',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/demanda-passageiro/demanda-passageiro.module').then(m => m.DemandaPassageiroPageModule)
  },
  {
    path: 'demanda-tempo-viagem/:idEstrategia',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/demanda-tempo-viagem/demanda-tempo-viagem.module').then(m => m.DemandaTempoViagemPageModule)
  },
  {
    path: 'estrategia-listar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/estrategia-listar/estrategia-listar.module').then(m => m.EstrategiaListarPageModule)
  },
  {
    path: 'estrategia-adicionar',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./pages/estrategia-adicionar/estrategia-adicionar.module').then(m => m.EstrategiaAdicionarPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
